export interface PaginationDTO {
  current_page: number;
  page_size: number;
  total_pages: number;
  total_count: number;
}

export interface QueryParamDTO {
  key: string;
  value: string;
}

export interface TextWithCountDTO {
  text: string;
  count: number;
}
export interface ColorLabelItemDTO {
  title: string;
  color_code: string;
}

export enum DataCellType {
  Text = 'text',
  TextWithCount = 'textWithCount',
  ColorLabel = 'colorLabel',
  Para = 'para',
  Datetime = 'datetime',
  PrimaryUnit = 'primaryUnit',
  User = 'user',
  Document = 'document',
  Documents = 'documents',
}

export enum DataCellSize {
  Auto = 0,
  User = 200,
  Date = 120,
  DateTime = 170,
  colorLabel = 150,
  Number = 60,
  Mobile = 110,

  Code = 50,
  Title = 300,
  Para = 500,
  Category = 200,
}
